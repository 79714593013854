import React from "react"
import EventFeed from "./EventFeed";
import useAllEvents from "../../hooks/use-all-events";
import EventItem from "../Event/EventItem";

const ProductFeed = () => {
  const allPost = useAllEvents()

  return allPost.map((node, index) => {
    return <EventItem key={index} node={node} />
  })
}

export default ProductFeed
