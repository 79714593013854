import { graphql, useStaticQuery } from "gatsby"

const useAllBlogPost = () => {
  const {
    directus: { news },
  } = useStaticQuery(graphql`query allBlogLinksQuery {
  directus {
    news (sort: "-date_created", filter: {status: {_eq: "published"}} ) {
      date_created
      headline
      id
      intro
      map
      slug
      text
      status
      image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}`)

  return news
}

export default useAllBlogPost
