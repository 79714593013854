import {graphql, useStaticQuery} from "gatsby"

const useAllEvents = () => {
    const {
        directus: {events},
    } = useStaticQuery(graphql`query {
 directus {
    events (filter: {status: { _eq: "published" }}, sort: "-date") {
      id
      status
      title
      text
      uploads {
        id
      }
      date
      image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}`)

    return events
}

export default useAllEvents
