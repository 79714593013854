import * as React from "react"
import Button from "../Button/Button"
import { PostItemStyles } from "./PostStyles"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FeaturedProductStyles} from "../Features/FeaturesStyles";

const BlogItem = ({ node }, key) => {
  const { title, id, intro, text, date, image } = node;
    let img = getImage(null);
    if (image !== null) {
        img = getImage(image.imageFile.childImageSharp)
    }
  return (
    <FeaturedProductStyles key={key} to={'/events/'+id}>
        {image !== null && (
        <GatsbyImage
            className="features__item--img"
            image={img}
        />)}

        <div className="features__item--content">
            <h4>{title}</h4>
            {text && <p dangerouslySetInnerHTML={{__html:text.substring(0, 120)+"..."}}></p>}

            <Button as="span" text="Weiterlesen" arrow={true} />
            <p>{new Date(date).toLocaleDateString()}</p>
        </div>
    </FeaturedProductStyles>
  )
}

export default BlogItem
