import React from "react"
import { graphql } from "gatsby"
import DefaultTemplate from "./default-template"
import FeedTemplate from "./feed-template"

const getTemplate = (contentfulPage) => {
  const { template } = contentfulPage

  switch (template) {
    case "feed":
      return <FeedTemplate {...contentfulPage} />

    default:
      return <DefaultTemplate {...contentfulPage} />
  }
}

const Page = ( data ) => {

  return <main>{getTemplate(data.data.directus.page[0])}</main>
}

export const data = graphql`
  query pageQuery($id: DirectusData_GraphQLStringOrFloat) {
    directus {
    page(filter:{id: { _eq: $id }}) {
      template
      feed
      title
        headerImage {
            id
            imageFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
      content 
    }
    }
  }
`

export default Page
