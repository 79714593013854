import * as React from "react"
import Button from "../Button/Button"
import { PostItemStyles } from "../Event/PostStyles"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FeaturedProductStyles} from "../Features/FeaturesStyles";

const BlogItem = ({ node }, key) => {
  const { headline, slug, intro, text, date_created, image } = node;
    let img = getImage(null);
    if (image !== null) {
        img = getImage(image.imageFile.childImageSharp)
    }
  return (
    <FeaturedProductStyles key={key} to={'/news/'+slug}>
        {image !== null && (
        <GatsbyImage
            className="features__item--img"
            image={img}
        />)}

        <div className="features__item--content">
            <h4>{headline}</h4>
            {intro && <p dangerouslySetInnerHTML={{__html:intro}}></p>}

            <Button as="span" text="Weiterlesen" arrow={true} />
            <p>{new Date(date_created).toLocaleDateString()}</p>
        </div>
    </FeaturedProductStyles>
  )
}

export default BlogItem
